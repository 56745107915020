import React, { useState } from "react";
import "./Register.css";

const Register = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    displayName: "",
    birthDate: "",
    location: "",
    googleUserId: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <div className="register-container">
      <h3 className="h3">Create an account</h3>
      <form className="input_form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="firstName"
          onChange={handleChange}
          placeholder="First Name"
        />
        <input
          type="text"
          name="lastName"
          onChange={handleChange}
          placeholder="Last Name"
        />
        <input
          type="text"
          name="displayName"
          onChange={handleChange}
          placeholder="Display Name"
        />
        <input
          type="email"
          name="email"
          onChange={handleChange}
          placeholder="Email"
        />
        <input
          type="password"
          name="password"
          onChange={handleChange}
          placeholder="Password"
        />
        <input
          type="password"
          name="password"
          onChange={handleChange}
          placeholder="Confirm password"
        />

        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;

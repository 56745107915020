import { createClient, Provider, fetchExchange } from "urql";

const client = createClient({
  url: "http://54.183.179.20:8080/v1/graphql",
  fetchOptions: () => {
    const token = localStorage.getItem("token");
    return {
      headers: { authorization: token ? `Bearer ${token}` : "" },
    };
  },
  exchanges: [fetchExchange],
});
//@ts-ignore
export const UrqlProvider: React.FC = ({ children }) => (
  <Provider value={client}>{children}</Provider>
);

import HomepageHeader from "./components/HomepageHeader";
import Register from "./screens/Register";
import { useState } from "react";

type AppInnerProps = {
  // Define props here
};

const AppInner: React.FC<AppInnerProps> = (props) => {
  // Destructure props here if needed
  const [signedIn, setSignedin] = useState(false);
  return (
    <div className="">
      <HomepageHeader />
      <div className="main">
        <Register />
      </div>
    </div>
  );
};

export default AppInner;

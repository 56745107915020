import React from "react";

type HomepageHeaderProps = {
  // Define props here
};

const HomepageHeader: React.FC<HomepageHeaderProps> = (props) => {
  // Destructure props here if needed

  return (
    <div className="header">
      <img
        src="/images/heartLogoWithText.png"
        alt="bee's kitchen logo"
        className="header__logo"
      />
  
    </div>
  );
};

export default HomepageHeader;
